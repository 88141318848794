.loginPage .column.leftColumn {
    padding-left: 20% !important;
}

.loginPage .column.rightColumn {
    padding-right: 20% !important;
}

@media screen and (max-width: 1500px) {
    .loginPage .column.leftColumn {
        padding-left: 16% !important;
    }

    .loginPage .column.rightColumn {
        padding-right: 16% !important;
    }
}

@media screen and (max-width: 1300px) {
    .loginPage .column.leftColumn {
        padding-left: 10% !important;
    }

    .loginPage .column.rightColumn {
        padding-right: 10% !important;
    }
}


@media screen and (max-width: 1100px) {
    .loginPage .column.leftColumn {
        padding-left: 6% !important;
    }

    .loginPage .column.rightColumn {
        padding-right: 6% !important;
    }
}
